.spinner-container {
  margin: 0 0 20px;
  text-align: center;
}
.spinner {
  display: block;
  width: 30px;
  height: 30px;
  margin: 0 auto 8px;
  border: 6px solid #ccc; /* Light grey */
  border-top: 6px solid #666; /* Blue */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.spinner-container .caption {
  font-size: 14px;
}

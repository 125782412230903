/* HEADER ------------------------------------------------------- */
#header,
#header * {
  box-sizing: border-box;
}
#header > .wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
}
#header > .wrap > *:first-child {
  margin-top: 0;
}
#header > .wrap > *:last-child {
  margin-bottom: 0;
}
#header .site-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 1em;
}

/* MENU BUTTON -------------------------------------------------- */
.menu-button,
.menu-button * {
  box-sizing: border-box;
}
.menu-button { }
.menu-button button {
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.menu-button button .menu-button-title {
  display: none;
}
.menu-button button .menu-button-wrapper {
  display: block;
  position: relative;
  height: 40px;
}
.menu-button button .menu-button-container {
  display: block;
  position: relative;
  top: 7px;
  width: 40px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}
.menu-button button .menu-button-container span {
  display: block;
  position: absolute;
  left: 0;
  height: 5px;
  width: 100%;
  background: #000;
  border-radius: 0px;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
.menu-button button .menu-button-container span:nth-child(1) {
  top: 0px;
}
.menu-button button .menu-button-container span:nth-child(2),
.menu-button button .menu-button-container span:nth-child(3) {
  top: 10px;
}
.menu-button button .menu-button-container span:nth-child(4) {
  top: 20px;
}
.menu-open .menu-button button .menu-button-container span:nth-child(1),
.menu-open .menu-button button .menu-button-container span:nth-child(4) {
  top: 20px;
  width: 0%;
  left: 50%;
}
.menu-open .menu-button button .menu-button-container span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.menu-open .menu-button button .menu-button-container span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media screen and (max-width: 500px) {

  /* HEADER ------------------------------------------------------- */
  #header > .wrap {
    
  }

}
